import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { ErrorMessage } from "@hookform/error-message";
import { addDays, formatDistanceToNowStrict } from "date-fns";
import Countdown from "react-countdown";
import { useFormContext } from "react-hook-form";
import { toast } from "react-hot-toast";
import { BiEraser } from "react-icons/bi";
import { twMerge } from "tailwind-merge";
import { FUNCTION_BASE_URL } from "@/utilities/constants";
import axios from "axios";
import {
  useAssessment,
  useQuestion,
  useAttempt,
} from "../TestAttemptController";
import { produce } from "immer";
import { useState } from "react";
import Swal from "sweetalert2";

const handleSubmit = async (
  test_attempt,
  test_section_question,
  values,
  save,
  setIsValidating
) => {
  try {
    if (test_section_question?.question?.pre_check_required) {
      setIsValidating(true);

      const pre_check_payload =
        test_section_question?.question?.pre_check_payload;
      const codeSnippet =
        test_section_question?.question?.pre_check_code_snippet;

      const studentName = test_attempt?.test_participant?.user?.fullName;

      const payload = {
        url: values?.answer?.answer,
        studentName: studentName,
        ...pre_check_payload,
      };

      try {
        const response = await axios.post(
          `https://us-central1-hackerrank-talentio.cloudfunctions.net/meritcurveAdmin/api/admin/assessments/validateSubmission/validate`,
          { payload, codeSnippet }
        );

        if (response.status === 200) {
          setIsValidating(false);
          save(true, true);
        } else {
          setIsValidating(false);
          toast.error("Validation failed!");
        }
      } catch (error) {
        setIsValidating(false);
        console.log("error: ", error);
        if (error.response) {
          setIsValidating(false);
          const errorMessages = error.response.data
            .map(
              (err) => `<div class="mb-2"><b>Error</b>: ${err.message}</div>`
            )
            .join("");
          Swal.fire({
            title: "Validation Errors",
            html:
              "<div class='text-sm mb-4'>We have detected following errors with your submission, please correct them and submit again</div>" +
              errorMessages,
            icon: "error",
          });
          console.log("error.response: ", error.response);
        } else {
          setIsValidating(false);
          toast.error("An error occurred!");
        }
      }
    } else {
      save(true, true);
    }
  } catch (error) {
    setIsValidating(false);
    toast.error("An error occurred!");
  }
};

function SingleLineType({ save, saving }) {
  const { isLast, test_section_question, questionData } = useQuestion();
  const test_attempt = useAttempt();
  const [isValidatating, setIsValidating] = useState(false);

  const { disable_copy_paste } = useAssessment();
  const {
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const values = watch();
  return (
    <div className="py-6 scrollBar sm:px-0">
      <div className="text-gray-700 md:px-5">
        <div className="flex items-center justify-between mt-5">
          <div>Type your answer here</div>
          <button
            onClick={() => setValue("answer", { answer: "" })}
            className="flex items-center gap-2 text-sm"
          >
            <BiEraser className="w-4 h-4" />
            Clear Response
          </button>
        </div>
        <div className="mt-5">
          <textarea
            className="w-full p-2 overflow-y-auto text-sm border-2 rounded-md"
            spellCheck={false}
            onPaste={(e) => {
              if (disable_copy_paste) {
                e.preventDefault();
                toast.error("Paste not allowed.");
                return false;
              }
            }}
            onCopy={(e) => {
              if (disable_copy_paste) {
                e.preventDefault();
                toast.error("Copy not allowed.");
                return false;
              }
            }}
            onCut={(e) => {
              if (disable_copy_paste) {
                e.preventDefault();
                toast.error("Cut not allowed.");
                return false;
              }
            }}
            rows={2}
            name="answer.answer"
            value={values?.answer?.answer ?? ""}
            onChange={(e) => {
              setValue(
                "answer",
                produce(values.answer, (draft) => {
                  if (!draft) {
                    draft = {};
                  }
                  draft.answer = e.target.value;
                  return draft;
                })
              );
            }}
          />
          <ErrorMessage
            errors={errors}
            name="answer.answer"
            className="text-red-600"
            component="div"
          />
        </div>
      </div>
      <div className="flex flex-col items-end justify-end w-full px-5 mt-5">
        <button
          onClick={() =>
            handleSubmit(
              test_attempt,
              test_section_question,
              values,
              save,
              setIsValidating
            )
          }
          type="button"
          className={twMerge(
            `px-5 py-2 text-sm flex items-center gap-2 text-white bg-blue-800 rounded-md lg:relative lg:w-auto`,
            saving || isValidatating
              ? "bg-gray-900 cursor-not-allowed bg-opacity-75"
              : ""
          )}
          disabled={saving || isValidatating}
        >
          {saving || isValidatating ? (
            <ArrowPathIcon className="w-5 h-5 animate-spin" />
          ) : null}
          {saving || isValidatating
            ? "Saving..."
            : isLast
            ? "Save"
            : "Save & Next"}
        </button>
        <Countdown
          autoStart={true}
          renderer={() => {
            return (
              <span className="text-xs">
                {`Updated ${
                  test_section_question?.test_question_submissions?.[0]
                    ? formatDistanceToNowStrict(
                        new Date(
                          test_section_question?.test_question_submissions?.[0].updated_at
                        ),
                        { addSuffix: true }
                      )
                    : "never"
                }`}
              </span>
            );
          }}
          date={addDays(new Date(), 1)}
          intervalDelay={5000}
        />
      </div>
    </div>
  );
}

export default SingleLineType;
