import AssessmentTimer from "@/components/attempts/AssessmentTimer";
import EndAssessmentSideOver from "@/components/attempts/EndAssessmentSideOver";
import QuestionDisplayComponent from "@/components/attempts/QuestionDisplayComponent";
import QuestionNavigation from "@/components/attempts/QuestionNavigation";
import QuestionStatPopover from "@/components/attempts/QuestionStatPopover";
import TestAttemptController from "@/components/attempts/TestAttemptController";
import InstructionButton from "@/components/attempts/instructionButton";
import QuestionTypeSwitch from "@/components/attempts/questionTypeSwitch";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Suspense, useState } from "react";
import toast from "react-hot-toast";
import { BiExitFullscreen, BiFullscreen } from "react-icons/bi";

function TestAttempt() {
  // const [mobileFooterOpen, setMobileFooterOpen] = useState(false);
  const [endTestSideOverOpen, setEndTestSideOverOpen] = useState(false);

  return (
    <TestAttemptController>
      {({
        data,
        test_section_question,
        endSectionHandler,
        endTestHandler,
        saveCurrentPosition,
        currentSectionAttemptedQuestionsStats,
        testTimerEnd,
        section_index,
        setCurrentQuestion,
        currentQuestion,
        questions_map,
        questionRef,
        setSectionChoose,
        isLast,
        section_wise_time_spent,
        allQuestionAttempted,
        questionData,
        currentSection,
        isFullScreen,
        openFullscreen,
        closeFullscreen,
      }) => {
        return (
          <main className="flex flex-col w-full h-full text-base bg-slate-50">
            <div className="flex items-center justify-between gap-5 px-5 py-5 text-white md:px-12 bg-slate-800">
              <div className="flex items-center gap-3">
                <div className="flex items-center gap-2">
                  {data?.tests_by_pk?.university?.icon && (
                    <img
                      src={data?.tests_by_pk?.university?.icon}
                      alt="Icon"
                      className="object-contain w-8 h-8"
                    />
                  )}

                  {data?.tests_by_pk?.university?.name ?? "Meritcurve"}
                </div>
                <span className="hidden md:block">|</span>
                <div className="flex-col hidden text-sm md:flex">
                  <span>
                    {data.test_attempts_by_pk.test_participant.user.fullName}
                  </span>
                  <span>{data.tests_by_pk.title}</span>
                </div>
              </div>
              <div className="flex items-center gap-5">
                <AssessmentTimer
                  {...{
                    data,
                    section_wise_time_spent,
                    testTimerEnd,
                    endTestHandler,
                    currentSection,
                    endSectionHandler,
                  }}
                />
                <div>
                  <button
                    className=""
                    onClick={() => {
                      /* View in fullscreen */

                      if (isFullScreen) {
                        closeFullscreen();
                      } else {
                        openFullscreen();
                      }
                    }}
                  >
                    {isFullScreen ? <BiExitFullscreen /> : <BiFullscreen />}
                  </button>
                </div>
                <button
                  className="px-5 py-1 border border-white rounded-md"
                  onClick={() => {
                    if (
                      !data.tests_by_pk.all_question_required ||
                      (data.tests_by_pk.all_question_required &&
                        allQuestionAttempted &&
                        data.tests_by_pk.test_sections.every(
                          (el, i) =>
                            i === section_index ||
                            (i !== section_index &&
                              el.test_attempt_sections[0]?.is_completed)
                        ))
                    ) {
                      setEndTestSideOverOpen(true);
                    } else {
                      toast.error("All questions are required to be attempted");
                    }
                  }}
                >
                  Finish Test
                </button>
                {endTestSideOverOpen && (
                  <EndAssessmentSideOver
                    data={data.tests_by_pk}
                    onClose={() => setEndTestSideOverOpen(false)}
                    open={endTestSideOverOpen}
                    onConfirm={async () => {
                      try {
                        await endTestHandler("user");
                        setEndTestSideOverOpen(false);
                      } catch (error) {
                        toast.error("Ending Assessment Failed");
                        console.log("error", error);
                      }
                    }}
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col flex-grow mx-5 -mt-2 border rounded-md">
              <div className="flex flex-wrap items-center gap-2 px-5 py-2 border-b shadow-md justify-evenly md:justify-between bg-slate-50">
                <div className="flex items-center gap-2">
                  <div>Choose Section</div>
                  <button
                    onClick={() => {
                      if (
                        !data.tests_by_pk.all_question_required ||
                        (data.tests_by_pk.all_question_required &&
                          allQuestionAttempted)
                      ) {
                        setSectionChoose(true);
                      } else {
                        toast.error(
                          "All questions are required to be attempted"
                        );
                      }
                    }}
                    className="flex items-center justify-between gap-5 px-3 py-2 bg-white border rounded-md md:w-56"
                  >
                    <div className="text-ellipsis">{currentSection?.title}</div>
                    <ChevronDownIcon className="w-4 h-4" />
                  </button>
                  <InstructionButton
                    testInstruction={data.tests_by_pk.instruction}
                    sectionInstruction={currentSection?.instructions}
                  />
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex flex-col gap-1">
                    <QuestionNavigation
                      currentQuestion={currentQuestion}
                      saveCurrentPosition={saveCurrentPosition}
                      setCurrentQuestion={setCurrentQuestion}
                      questions_map={questions_map}
                      questionData={questionData}
                    />
                    <QuestionStatPopover
                      questions_map={questions_map}
                      currentSectionAttemptedQuestionsStats={
                        currentSectionAttemptedQuestionsStats
                      }
                      questionData={questionData}
                      currentSection={currentSection}
                    />
                  </div>
                </div>
              </div>
              {!test_section_question ? (
                <div className="py-40 text-center">No Questions Found</div>
              ) : (
                <div className="flex flex-col items-stretch flex-grow bg-white md:grid md:grid-cols-2">
                  <div className="flex flex-col w-full gap-3 overflow-y-auto">
                    <QuestionDisplayComponent
                      is_marked={
                        test_section_question?.test_question_submissions[0]
                          ?.is_marked
                      }
                      test_attempt_id={data.test_attempts_by_pk.id}
                      test_section_id={
                        data.test_attempts_by_pk.current_section_id
                      }
                      test_section_question_id={test_section_question.id}
                      title={test_section_question.question.data.title}
                      currentQuestion={currentQuestion}
                      test_section_question={test_section_question}
                      section={currentSection}
                      hide_marks={data.tests_by_pk.hide_marks}
                    />
                  </div>
                  <div className="flex flex-col flex-grow bg-white md:h-full min-h-[24rem] md:overflow-y-auto">
                    <Suspense
                      fallback={
                        <div className="flex items-center justify-center w-full min-h-[500px] text-2xl font-semibold">
                          <div className="w-6 h-6 spinner-grow" />
                          <div className="ml-3">Loading</div>
                        </div>
                      }
                    >
                      <QuestionTypeSwitch
                        ref={questionRef}
                        key={test_section_question?.id ?? "no_question_switch"}
                        type={test_section_question.question.type}
                        data={data}
                        test_section_question={test_section_question}
                        next={() => {
                          const nextQuestion =
                            questionData?.test_section_questions?.[
                              questions_map?.[currentQuestion + 1]
                            ];

                          if (nextQuestion) {
                            setCurrentQuestion(currentQuestion + 1);
                            saveCurrentPosition(nextQuestion.id);
                          }
                        }}
                      />
                    </Suspense>
                  </div>
                </div>
              )}
            </div>
          </main>
        );
      }}
    </TestAttemptController>
  );
}

export default TestAttempt;
