import { useAuth } from "@/components/AuthProvider";
import CodingQuestion from "@/components/attempts/questions/codingQuestion";
import { FUNCTION_BASE_URL } from "@/utilities/constants";
import { useMonaco } from "@monaco-editor/react";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Base64 } from "js-base64";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { toast } from "react-hot-toast";
import { BiEraser } from "react-icons/bi";
import { BsInfoCircle } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import Modal from "react-modal";
import Select from "react-select";
import swal from "sweetalert";
import {
  useAssessment,
  useAttempt,
  useQuestion,
} from "../TestAttemptController";
import { produce } from "immer";

const modes = {
  45: "UNKNOWN",
  46: "shell",
  47: "UNKNOWN",
  1011: "UNKNOWN",
  75: "c",
  1013: "c",
  1001: "c",
  48: "c",
  49: "c",
  50: "c",
  51: "csharp",
  1022: "csharp",
  1021: "csharp",
  1023: "csharp",
  76: "cpp",
  1014: "cpp",
  1002: "cpp",
  52: "cpp",
  53: "cpp",
  54: "cpp",
  1015: "cpp",
  1012: "cpp",
  1003: "c",
  86: "clojure",
  77: "UNKNOWN",
  55: "UNKNOWN",
  56: "UNKNOWN",
  57: "UNKNOWN",
  58: "UNKNOWN",
  44: "plaintext",
  87: "fsharp",
  1024: "fsharp",
  59: "UNKNOWN",
  60: "go",
  88: "UNKNOWN",
  61: "UNKNOWN",
  62: "java",
  1004: "java",
  1005: "java",
  63: "javascript",
  78: "kotlin",
  64: "lua",
  1006: "c",
  1007: "cpp",
  1008: "python",
  1009: "python",
  79: "objective-c",
  65: "UNKNOWN",
  66: "UNKNOWN",
  67: "pascal",
  85: "perl",
  68: "php",
  43: "plaintext",
  69: "UNKNOWN",
  70: "python",
  71: "python",
  1010: "python",
  80: "r",
  72: "ruby",
  73: "rust",
  81: "UNKNOWN",
  82: "sql",
  83: "swift",
  74: "typescript",
  84: "vb",
};
// const JUDGE_URL = "http://localhost:4000";
const JUDGE_URL = `${FUNCTION_BASE_URL}/compiler`;
function CodingType({ languages, save, saving }) {
  const { test_section_question } = useQuestion();
  const { disable_copy_paste } = useAssessment();
  const { id: attempt_id, current_section_id } = useAttempt();

  const { token } = useAuth();
  const editorRef = useRef();
  const [isCompiling, setIsCompiling] = useState(false);
  const [apiError, setApiError] = useState(false);
  const monaco = useMonaco();
  // const [loadingMessage, setLoadingMessage] = useState(null);
  const [compilerResults, setCompilerResults] = useState(null);
  const [isCodeChanged, setIsCodeChanged] = useState(false);
  const [callback, setCallback] = useState(null);
  const queryClient = useQueryClient();
  const compilerStatusCodes = useMemo(
    () => ({
      1: { id: 1, description: "In Queue" },
      2: { id: 2, description: "Processing" },
      3: { id: 3, description: "Accepted" },
      4: { id: 4, description: "Wrong Answer" },
      5: { id: 5, description: "Time Limit Exceeded" },
      6: { id: 6, description: "Compilation Error" },
      7: { id: 7, description: "Runtime Error (SIGSEGV)" },
      8: { id: 8, description: "Runtime Error (SIGXFSZ)" },
      9: { id: 9, description: "Runtime Error (SIGFPE)" },
      10: { id: 10, description: "Runtime Error (SIGABRT)" },
      11: { id: 11, description: "Runtime Error (NZEC)" },
      12: { id: 12, description: "Runtime Error (Other)" },
      13: { id: 13, description: "Internal Error" },
      14: { id: 14, description: "Exec Format Error" },
    }),

    []
  );

  const startExecutionofTestCases = useCallback(
    async (
      language_id,
      code,
      test_section_question_id,
      label,
      mode,
      type = "RUN"
    ) => {
      setIsCompiling(true);
      setIsCodeChanged(false);

      try {
        if (!code.trim()) {
          throw new Error("Code is empty");
        }
        let res = await axios.post(
          `${JUDGE_URL}/validateTestCases?attempt_id=${attempt_id}`,
          {
            test_attempt_id: attempt_id,
            test_section_id: current_section_id,
            test_section_question_id: test_section_question_id,
            language_id: language_id,
            source_code: Base64.encode(code || ""),
            label: label,
            mode: mode,
            type,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${token.current}`,
            },
          }
        );

        if (res && res.status === 200) {
          await save(false);
          let finalResults = res.data.submissions.map((el) => {
            if (el.hidden) {
              return {
                status: compilerStatusCodes[el.status.id],
                hidden: el.hidden,
              };
            }
            return {
              input: Base64.decode(el.stdin || ""),
              output: Base64.decode(
                el.compile_output || el.stderr || el.stdout || ""
              ),
              expectedOutput: Base64.decode(el.expected_output || ""),
              status: compilerStatusCodes[el.status.id],
              hidden: el.hidden,
            };
          });
          // if (type === "SUBMIT") {
          //   await queryClient.refetchQueries(["assessment", "attempt"]);
          // }
          setIsCompiling(false);

          setCompilerResults(finalResults);
          if (document.getElementById("results")) {
            document.getElementById("results").scrollIntoView({
              behavior: "smooth",
              block: "start",
              inline: "start",
            });
          }

          setApiError(false);
          if (type === "SUBMIT") {
            queryClient.setQueryData(
              ["assessment", "attempt", current_section_id],
              (currentSectionQuestionData) => {
                if (!currentSectionQuestionData) {
                  return currentSectionQuestionData;
                }
                return produce(currentSectionQuestionData, (draft) => {
                  const d = res.data;
                  const question = draft.test_section_questions.find(
                    (el) => el.id === d.test_section_question_id
                  );
                  if (question) {
                    if (question.test_question_submissions[0]) {
                      question.test_question_submissions[0].id = d.id;
                      question.test_question_submissions[0].is_marked =
                        d.is_marked ??
                        question?.test_question_submissions?.[0]?.is_marked;
                      question.test_question_submissions[0].updated_at =
                        d.updated_at ?? new Date().toISOString();
                      question.test_question_submissions[0].time_taken =
                        d.time_taken ??
                        question?.test_question_submissions?.[0]?.time_taken;
                      question.test_question_submissions[0].compiler_attempts_left =
                        d.compiler_attempts_left ??
                        question?.test_question_submissions?.[0]
                          ?.compiler_attempts_left;
                      question.test_question_submissions[0].is_corrected =
                        d.is_corrected ??
                        question?.test_question_submissions?.[0]
                          ?.is_corrected ??
                        false;
                    } else {
                      question.test_question_submissions[0] = {
                        id: d.id,
                        is_marked:
                          d.is_marked ??
                          question?.test_question_submissions?.[0]?.is_marked,
                        answer:
                          d.answer ??
                          question?.test_question_submissions?.[0]?.answer,
                        updated_at: d.updated_at ?? new Date().toISOString(),
                        time_taken:
                          d.time_taken ??
                          question?.test_question_submissions?.[0]?.time_taken,
                        compiler_attempts_left:
                          d.compiler_attempts_left ??
                          question?.test_question_submissions?.[0]
                            ?.compiler_attempts_left,
                        is_corrected:
                          d.is_corrected ??
                          question?.test_question_submissions?.[0]
                            ?.is_corrected ??
                          false,
                      };
                    }
                  }
                  return draft;
                });
              }
            );
          }
          return true;
        } else {
          toast.error(
            "Failed to fetch results, please refresh the page and try again"
          );

          setApiError(true);
        }
      } catch (error) {
        setIsCompiling(false);

        console.log("error", error);
        if (error?.code === "ERR_NETWORK") {
          toast.error(
            "Network request failed, Please check your internet connection."
          );
        } else if (error?.code === "ECONNABORTED") {
          toast.error(
            "Network request failed, Please check your internet connection speed."
          );
        } else {
          toast.error(
            error?.response?.data?.error?.message ?? "Something went wrong "
          );
        }

        console.log("Error", error);
        return false;
      }
    },
    [
      save,
      token,
      attempt_id,
      current_section_id,
      queryClient,
      compilerStatusCodes,
    ]
  );

  const {
    setValue,
    formState: { isDirty },
    watch,
  } = useFormContext();
  const values = watch("answer");

  useEffect(() => {
    setIsCodeChanged(false);
    setCompilerResults(null);
  }, []);
  return (
    <div className="flex flex-col flex-grow w-full pb-24">
      <div className="flex flex-col flex-grow w-full gap-3">
        <div className="flex items-center justify-end gap-5 px-5 mt-5">
          <button
            onClick={() =>
              swal({
                title: "Are you sure?",
                text: "Are you sure that you want reset your code to starter snippet?",
                icon: "warning",
                dangerMode: true,
                button: {
                  text: "Yes, Reset my code",
                },
              }).then((willDelete) => {
                if (willDelete) {
                  setValue(
                    "answer",
                    test_section_question.question.programming_snippets.length >
                      0
                      ? {
                          code: test_section_question.config.hide_starter_code
                            ? ""
                            : test_section_question.question
                                .programming_snippets[0]?.snippet ??
                              test_section_question.question
                                .programming_snippets[0]?.language.snippet,
                          language: {
                            label:
                              test_section_question.question
                                .programming_snippets[0].language?.name,
                            value:
                              test_section_question.question
                                .programming_snippets[0].language?.id,
                            mode: test_section_question.question
                              .programming_snippets[0].language?.mode,
                          },
                        }
                      : {
                          code: test_section_question.config.hide_starter_code
                            ? ""
                            : languages[0]?.snippet,
                          language: {
                            label: languages[0]?.name,
                            value: languages[0]?.id,
                            mode: languages[0]?.mode,
                          },
                        }
                  );
                }
              })
            }
            className="flex items-center gap-2 text-sm"
          >
            <BiEraser className="w-4 h-4" />
            Reset to Starter Snippet
          </button>
          <Select
            isSearchable={false}
            isMulti={false}
            value={values?.language}
            name={`answer.language`}
            onChange={(e) => {
              setValue(
                "answer",
                test_section_question.question.programming_snippets.length > 0
                  ? {
                      code: test_section_question.config.hide_starter_code
                        ? ""
                        : test_section_question.question.programming_snippets.find(
                            (el) => el.language.id === e.value
                          )?.snippet ??
                          test_section_question.question.programming_snippets.find(
                            (el) => el.language.id === e.value
                          )?.language.snippet,
                      language: {
                        label:
                          test_section_question.question.programming_snippets.find(
                            (el) => el.language.id === e.value
                          ).language?.name,
                        value:
                          test_section_question.question.programming_snippets.find(
                            (el) => el.language.id === e.value
                          ).language?.id,
                        mode: test_section_question.question.programming_snippets.find(
                          (el) => el.language.id === e.value
                        ).language?.mode,
                      },
                    }
                  : {
                      code: test_section_question.config.hide_starter_code
                        ? ""
                        : languages.find((el) => el.id === e.value)?.snippet,
                      language: {
                        label: languages.find((el) => el.id === e.value)?.name,
                        value: languages.find((el) => el.id === e.value)?.id,
                        mode: languages.find((el) => el.id === e.value)?.mode,
                      },
                    }
              );
              monaco.editor.setModelLanguage(
                editorRef.current.getModel(),
                modes[e.value]
              );
              // setIsCodeChanged(true);
            }}
            className="w-1/4 lg:w-1/4"
            options={
              test_section_question.question.programming_snippets.length > 0
                ? test_section_question.question.programming_snippets.map(
                    ({ language, snippet }) => ({
                      label: language.name,
                      value: language.id,
                      mode: language.mode,
                      starterCode: snippet ?? language.snippet,
                    })
                  )
                : languages.map((el) => ({
                    label: el.name,
                    value: el.id,
                    mode: el.mode,
                    starterCode: el.snippet,
                  }))
            }
            placeholder="Select a language"
          />
        </div>
        <div className="text-gray-700">
          <CodingQuestion
            editorRef={editorRef}
            setFieldValue={setValue}
            value={values ?? ""}
            is_submission_limited={
              // test_section_question?.is_submission_limited
              false
            }
            compiler_attempts_left={
              !isNaN(
                test_section_question?.test_question_submissions[0]
                  ?.compiler_attempts_left
              )
                ? test_section_question?.test_question_submissions[0]
                    ?.compiler_attempts_left
                : test_section_question?.max_coding_submissions || 0
            }
            test_section_question_id={test_section_question.id}
            startExecutionofTestCases={startExecutionofTestCases}
            isCompiling={isCompiling}
            compilerResults={compilerResults}
            apiError={apiError}
            dirty={isDirty}
            setIsCodeChanged={setIsCodeChanged}
            loadingMessage={
              // loadingMessage
              //   ? loadingMessage
              //   :
              "Checking test cases..."
            }
            save_without_compiling={
              test_section_question?.config?.save_without_compiling
            }
            disable_autocomplete={
              test_section_question?.config?.disable_autocomplete
            }
            save={save}
            saving={saving}
            test_section_question={test_section_question}
            disable_copy_paste={disable_copy_paste}
          />
        </div>
      </div>

      <Modal
        isOpen={callback !== null}
        onRequestClose={() => {
          setCallback(null);
        }}
        htmlOpenClassName="overflow-hidden"
        bodyOpenClassName="overflow-hidden"
        className="inset-x-auto inset-y-auto w-64 bg-white rounded-md sm:w-80 focus:outline-none"
        overlayClassName="transition-all ease-in-out duration-300 flex z-50 justify-center items-center bg-opacity-75 bg-black inset-0 fixed p-8"
      >
        <header className="flex justify-end p-5">
          <button
            onClick={() => {
              setCallback(null);
            }}
          >
            <MdClose className="w-6 h-6" />
          </button>
        </header>
        <div className="flex justify-center px-5 mb-5">
          <BsInfoCircle className="w-16 h-16 text-yellow-600" />
        </div>
        <div className="flex flex-col gap-2 px-5 text-center">
          Your code will not be scored unless you submit it so either submit
          your code now or remember to come back to do it later. Do you wanna
          continue to a different question?
        </div>
        <div className="flex justify-center gap-5 my-5">
          <button
            onClick={() => {
              setCallback(null);
            }}
            className="px-5 py-2 border border-gray-500 rounded-md"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              if (callback) callback();
            }}
            className="px-5 py-2 bg-gray-500 rounded-md"
          >
            Continue
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default CodingType;
